<template>
    <b-row class="justify-content-md-center">
        <b-col cols="4">
            <b-card header="Вход">
                <b-input v-model="username" placeholder="Логин"/><br />
                <b-input v-model="password" placeholder="Пароль" type="password"/><br />
                <b-alert :show="error.show" variant="danger" dismissible>{{error.text}}</b-alert>
                <b-button block @click="login" variant="primary">Войти</b-button><br />
                <router-link to='/'>Отмена</router-link>
            </b-card>
            <br />
            <b-link href="https://roecocity.ru">roecocity.ru</b-link>
        </b-col>
    </b-row>
</template>

<script>
import axios from 'axios'
import url from '../utils/url'
import response from '../utils/response.js'
import helpers from '../model/helpers.js'

export default {
    name: 'eco-login',
    data() {
        return {
            error : {
                show: false,
                text: ''
            },
            username: '',
            password: ''
        }
    },
    methods: {
        login() {
            let _this = this
            this.hideError()
            axios.get(url('/user/login'), {
                params: {
                    username: this.username,
                    password: this.password
                }
            }).then(resp => {
                resp = response(resp)
                if(resp && resp.access === true) {
                    window.$key = resp.key
                    _this.$cookie.set('key', resp.key, 1);
                    _this.$router.push('/workspace')
                    this.loadHelpers()
                } else {
                    _this.showError(resp.reason)
                }
            }).catch(error => _this.showError(error.message))
        },
        loadHelpers() {
            let keyFromCookie = this.$cookie.get('key')
            if(keyFromCookie && keyFromCookie.length > 10) {
                window.$key = keyFromCookie
                helpers.load()
            }
        },
        showError(msg) {
            const errors = [
                ['user not found', 'Пользователь не найден'],
                ['password not correct', 'Неверный пароль'],
                ['request broken', 'Не указаны логин и пароль'],
                ['Network Error', 'Ошибка сети :('],
            ]
            this.error.show = true
            for(let errorItem of errors) {
                if(errorItem[0] === msg) {
                    msg = errorItem[1]
                }
            }
            this.error.text = msg
        },
        hideError() {
            this.error.show = false
            this.error.text = ''            
        }
    },
}
</script>