 export default function() { 
    return {
        filters: {
            balance1: 10000,
            balance2: 20000,
            types : {
                selected: 'company',
                options: [
                    //{ value: 'all', text: 'По всем (ЮЛ + ФЛ)'},
                    { value: 'company', text: 'Юридические лица и приравненные к ним'},
                    //{ value: 'customer', text: 'Физические лица и приравненные к ним'},
                ]
            },
            views : {
                selected: 'table',
                options: [
                    { value: 'table', text: 'Отображать как таблицу'},
                    // { value: 'tree', text: 'Отображать как иерархию'},
                    // { value: 'chart', text: 'Отображать как график'},
                ]
            },
            groupby : {
                selected1: 'region',
                selected2: 'group',
                options: [
                    { value: null, text: 'Не группировать' },
                    { value: 'region', text: 'Группировать по территории'},
                    { value: 'group', text: 'Группировать по группе'},
                    { value: 'balance', text: 'Группировать по задолженности'},
                ]
            },
            debtages: {
                selected: null,
                options: [
                    { value: null, text: 'Возраст задолженности', disabled: true},
                    ...buildAges()
                ]
            },
            sortby : {
                selected: 'name',
                options: [
                    { value: 'inn', text: 'Сортировать по ИНН'},
                    { value: 'contract', text: 'Сортировать по договору'},
                    { value: 'name', text: 'Сортировать по названию'},
                    { value: 'balance', text: 'Сортировать по задолженности'},
                ]
            },
            regions: {
                selected: null,
                options: buildOptions('region', 'Выбрать регион (если нет будут все)')
            },
            groups: {
                selected: null,
                options: buildOptions('customer_group', 'Выбрать группу (если нет будут все)')
            }
        }
    }
}

import helpers from '../model/helpers.js'

function buildAges() {
    let res = []
    for(let i = 1; i <= 12; i++)
        res.push({ value: i, text: `${i} месяцев и более`})
    return res
}

function buildOptions(helper_name, text) {
    return [
        { value: null, text: text},
        ...helpers.getHelper(helper_name).map(region => {
            return {
                value: region.mid,
                text: region.name
            }
        })
    ]
}

