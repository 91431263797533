<template>
    <div>
        <b-input v-model="query" @keyup.enter="loadList"></b-input>
        <p>Произвольный поиск: номер суда, район, населенный пункт, улица, фамилия судьи или оставьте пустым (тогда отобразится полный список).</p>
        <b-button variant="primary" block @click="loadList" >Найти</b-button>
        <b-spinner v-if="spinner === true" class="spinner_" label="Loading..."></b-spinner>
        <h3 v-if="error" class="text-danger">{{error}}</h3>
        {{court.title}}
        <b-table v-if="list && list.length > 0  && spinner === false" :items="showedList" :fields="fields" small >
            <template v-slot:cell(title)="row">
                <b-row>
                    <b-col class="text-sm-left">
                        <b-button v-if="!row.item.area" @click="showDetailed(row.item)" variant="link">{{row.item.id}}. {{row.item.title}}</b-button>
                        <b-button v-if="row.item.area" @click="showDetailed(row.item)" variant="link">{{row.item.id}}/{{row.item.index}}. {{row.item.area}}, {{row.item.zones}}, {{row.item.note}}</b-button>
                    </b-col>
                </b-row>
                <b-card v-if="checkDetailed(row.item)">
                    <b-button @click="useJudge(row.item.id)" variant="success" block>Использовать</b-button>
                    <b-button @click="showDetailed(0)" variant="secondary" block>Назад к списку</b-button>
                    <br />
                    <b-row>
                        <b-col class="text-sm-left">
                            ID: {{row.item.id}}<br/>
                            {{row.item.position}} <b>{{row.item.judge}}</b><br/>
                            {{row.item.address}}<br/>
                            <a :href="'mailto:' + row.item.email">{{row.item.email}}</a><br/>
                            <a :href="'tel:' + row.item.phone">{{row.item.phone}}</a><br/>
                            <a :href="row.item.link" target="blank">{{row.item.link}}</a><br/>
                        </b-col>
                    </b-row>
                    <b-row v-if="row.item.id">
                        <b-col class="text-sm-left">
                            <br />
                            <b>Зоны подсудности судебного участка:</b>
                            <br />
                            <span v-for="(area, index) of selectedAreas" :key="index">
                                <b>{{index + 1}}. {{area.area}} </b>
                                <span class="text-danger">{{area.zones}} </span> 
                                <i class="text-primary">{{area.note}}</i><br /></span>
                        </b-col>
                    </b-row>
                </b-card>
            </template>
        </b-table>
        <center v-if="list && list.length === 0 && spinner === false">
            <br/><b class="text-danger">По данному запросу ничего не найдено.</b>
            <br/><span class="text-secondary">Измените поисковую фразу и попробуйте заново, или оставьте поле пустым, нажмите Найти и выберите из полного списка.</span>
        </center>
    </div>
</template>

<script>
import axios from 'axios'
import params from '../utils/params.js'
import url from '../utils/url.js'
import response from '../utils/response.js'

export default {
    props: {
        query: String,
        selected: Object,
        close: Function
    },
    data() {
        return {
            selectedAreas: [],
            list: [],
            fields: [
                { key: 'title', label: ''}
            ],
            court: {
                id: null
            },
            error: null,
            obj: null,
            detailed: { id: 0, index: 0},
            spinner: false
        }
    },
    methods: {
        checkDetailed(item) {
            if(this.detailed && item) {
                if(item.index > 0 && item.id > 0) {
                    return item.id === this.detailed.id && item.index === this.detailed.index
                } else {
                    return item.id === this.detailed.id 
                }
            } return false
        },
        showDetailed(item) {
            this.detailed = {
                id: item.id,
                index: item.index
            }
            this.selectedAreas = this.selectedAreas = [{
                    area: 'загрузка детальной информации',
                    note: '...'
                }]
            axios.get(
                url('/judges/list'), 
                params({ query: item.id }))
            .then(resp => {
                let res = response(resp)
                if(res && res.id) {
                    this.selectedAreas = res.areas
                }
            })
            .catch(err => {
                this.selectedAreas = [{
                    area: 'ошибка при загрузке',
                    note: err.message
                }]
            })
        },
        useJudge(id) {
            let res = this.list.filter(item => item.id === id)
            this.$emit('update:selected', res[0])
            if(this.close) {
                this.close()
            }
        },
        loadList() {
            this.detailed = {
                id: 0,
                index: 0
            }
            this.selectedAreas = []
            this.list = []
            this.court.id = null
            this.error = null
            this.obj = null
            this.fields[0].label =''
            this.spinner = true
            axios.get(
                url('/judges/list'), 
                params({ query: this.query }))
            .then(resp => {
                this.obj = response(resp)
                if(this.obj && this.obj.items) {
                    this.list = this.obj.items
                    this.fields[0].label = 'Найдено: ' + this.list.length
                }
                else if(this.obj && this.obj.id) {
                    this.court = this.obj
                }
                else this.error = 'unknow response'
                this.court = this.obj
                this.spinner = false
            })
            .catch(err => {
                this.error = err
                this.spinner = false
            })
        }
    },
    computed: {
        showedList() {
            if(this.detailed.id > 0 && this.detailed.index > 0) {
                return this.list.filter(i => i.id === this.detailed.id && i.index === this.detailed.index)
            } 
            if(this.detailed.id > 0) {
                return this.list.filter(i => i.id === this.detailed.id )
            } 
            return this.list
        }
    }
}
</script>

<style scoped>
    .spinner_ {
        margin: 30px; }
</style>