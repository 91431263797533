<template>
    <table style="width:100%; border: 0px;">
        <tbody v-if="isGroupWithSubgroups === true">
            <tr v-for="(item, index) of items" :key="index">
                <td class="tdclass">
                    <table style="width:100%;" :style="headerStyle">
                        <tr>
                            <td class="text-left">{{levelTag}} {{item.title}} {{groupvalue(item)}}</td>
                            <td class="text-right">
                            </td>
                        </tr>
                    </table>
                    <debt-tree :items="item.items" :level="nextlevel" :start="item.start"/>
                    <!-- <b-pagination  v-if="!item.isfull" 
                        :total-rows="item.total" 
                        per-page="10" 
                        @change="loadpage"
                        first-number last-number  size="sm"/> -->
                </td>
            </tr>
        </tbody>
        <tbody v-if="isGroupWithItems === true">
            <tr>
                <td :style="tdfix" class="text-left">
                    <b-link @click="showhide">{{ showhidelink() }}</b-link>
                </td>
                <td :style="tdfix" class="text-left"></td>
                <td class="text-right"><b>Сумма:</b></td>
                <td :style="tdfix" class="text-right"><b>{{fmtnum(summaryBalance)}}</b></td>
            </tr>
            <template v-if="showitems">
                <tr v-for="(item, index) of items" :key="index">
                    <td :style="tdfix" class="text-left">{{ currentstart + index + 1}}. {{item.contract}}</td>
                    <td :style="tdfix" class="text-left">{{item.inn}}</td>
                    <td class="text-left">{{item.name}}</td>
                    <td :style="tdfix" class="text-right">{{fmtnum(item.balance)}}</td>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<script>
import helpers from '../model/helpers.js'
import axios from 'axios'
import params from '../utils/params.js'
import url from '../utils/url.js'
import response from '../utils/response.js'

export default {
    name: 'debt-tree',
    props: {
        request: {},
        start: Number(0),
        items: {},
        level: Number(0),
    },
    data() {
        return {
            tdfix: 'width:150px; vertical-align:top;',
            showitems: true,
        }
    },
    methods: {
        loadpage(page) {
            alert(page)
            this.items.items = []
            this.request = {
                    type: this.request.type,
                    balance1: this.request.balance1,
                    balance2: this.request.balance2,
                    region: this.request.region,
                    group: this.request.group,
                    debtage: this.request.debtage,
                    view: this.request.view,
                    sortby: this.filters.sortby.selected 
                }
            this.error = null
            this.spinner = true
            axios.get(url('/debt/list'), params(this.request))
            .then(resp => {
                let res = response(resp)
                if(res && res.list) {
                    this.list = res.list
                    this.count = Number(res.count)
                    if(Number(res.count) === 0) {
                        this.error = 'По данному запросу ничего не найдено'
                    }
                } else this.error = 'unknown response'
            })
            .catch(err => { this.error = err.message })
            .finally(() => this.spinner = false)
        },
        fmtnum(num) { return num.toLocaleString('ru-RU', {minimumFractionDigits: 2, maximumFractionDigits: 2})},
        showhide() {
            this.showitems = !this.showitems
        },
        showhidelink() { return this.showitems ? 'свернуть [-]' : 'развернуть [+]' },

        groupvalue(item) {
            let pairs = [
                ['region', 'region', v => Number(v), true],
                ['group', 'customer_group', v => Number(v), true],
                ['balance', 'customer_group', null, false ]
            ]
            if(item.group === true) {
                for(let element of pairs) {
                    if(item.groupby === element[0]) {
                        if(element[3] === true) {
                            let helper = helpers.getHelper(element[1])
                            let func = element[2]
                            let value = helper.filter(h => h.mid === func(item.id))
                            return value.length === 1 ? value[0].name : 'н/д'
                        } else {
                            return item.id
                        }
                    } 
                }
            }  
            return 'NO_GROUP'
        },
    },
    computed: {
        currentstart() {
            return Number.isInteger(this.start) ? this.start : 0
        },
        isGroupWithItems() {
            return this.items && 
                Array.isArray(this.items) && 
                this.items.length >= 0 && 
                !this.isGroupWithSubgroups
                ? true : false 
        },
        isGroupWithSubgroups() { 
            return this.items && 
                Array.isArray(this.items) &&  
                this.items.length > 0 && 
                this.items[0].group === true
                ? true : false
        },
        levelTag() {
            let tags = [':', '::', ':::']
            return tags[this.level]
        },
        summaryBalance() {
            return this.isGroupWithItems > 0 ? 
                this.items.reduce((a, i) => a + i.balance, 0).toLocaleString('ru-RU', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '-'
        },
        nextlevel() { return this.level + 1 },
        headerStyle() { 
            let color = ['#bbffbb','#ddffdd','#eeffee']
            return `background-color: ${color[this.level]}; border-top: 1px;` 
        },
    }
}
</script>

<style>
.tdclass {padding: 0px; margin: 0px;}
</style>