<template>
    <div>
        <sub-nav-row title="Справочники" />
    </div>
</template>

<script>
export default {
    name:'eco-helpers'
}
</script>