import axios from 'axios'
import url from '../utils/url'
import params from '../utils/params.js'
import response from '../utils/response.js'
import log from '../utils/log.js'
import Vue from 'vue'

export default {
    load, getHelper
}

async function load() {
    Vue.prototype.$helpers = {}
    axios.get(
        url('/helpers'),
        params()
    )
    .then(resp => {
        Vue.prototype.$helpers = response(resp)
        log('helpers')
        log(Vue.prototype.$helpers)
        return true
    })
    .catch(err => {
        log('error')
        log(err)
        Vue.prototype.$helpers.error = err
        return false
    })
}

function getHelper(name) {
    if(Vue.prototype.$helpers[name]) {
        return Vue.prototype.$helpers[name]
    } else {
        return Vue.prototype.$helpers[name]
    }
}