import axios from 'axios'
import qbody from '../utils/qbody.js'
import url from '../utils/url.js'
import log from '../utils/log.js'
import { saveAs } from 'file-saver'

export default function (_this) {
    if(_this.invoicetype && _this.list.selected.codes.length > 0) {
        _this.spinerAction()
        axios.post(
            url('/sbis/create'), 
            qbody({ 
                codes: _this.list.selected.codes, 
                excludes: _this.list.selected.excludes, 
                period: _this.period,
                start: _this.verify.start,
                end: _this.verify.end,
                invoicetype: _this.invoicetype
            }),
            { responseType: 'blob' })
        .then(resp => {
            let d = new Blob([resp.data]) 
            let fname = `${resp.headers['content-type']}`.split('; filename=').slice(-1)
            fname = fname[0].replace('"','').replace('"','')
            log(fname)
            log(resp.headers)
            saveAs(d, fname)
            _this.spinerAction(0)
        })
        .catch(err => {
            _this.spinerAction(0)
            log(err)
            return err
        })
    }
}