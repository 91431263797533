<template>
    <div>
        <h5>Отчеты по договору "РосДолг"</h5>
        <hr/>
        <b-row h-align="left">
            <b-col cols=3>
                <b-overlay :show="spinner">
                    <b-card>
                        <b-form-select v-model="reptype" class="mb-3" @change="reportTypeChange">
                            <b-form-select-option value="payments">Оплаты за период</b-form-select-option>
                            <b-form-select-option value="noinvoices">Без начислений</b-form-select-option>
                        </b-form-select>
                        <br />
                        <b-form-datepicker v-model="start" locale="ru-RU" placeholder="Начальная дата" 
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"/>
                        <br />
                        <b-form-datepicker v-model="end" locale="ru-RU" placeholder="Конечная дата" 
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"/>
                        <br />
                        <b-button block variant="success" @click="loadList">Отобразить список</b-button>
                        <br />
                        <b-button block variant="danger"  @click="loadReport('pdf')">Загрузить PDF</b-button>
                        <b-button block variant="danger"  @click="loadReport('xlsx')">Загрузить Excel</b-button>
                        <hr/>
                        <p>Период с {{ df(start) }}<br/>по {{ df(end) }}</p>
                        <template v-if="reptype==='payments'">
                            <hr/>
                            <b-check v-model="noEmpty">Отобразить только с оплатами за указанные период</b-check>
                        </template>
                    </b-card>
                </b-overlay>
                <p v-if="error != null" style="color:red;">{{ error }}</p>
            </b-col>
            <b-col>
                <b-table v-if="reportList && reportList.length > 0" small fixed borderless responsive="sm" :items="preparedList" :fields="reportFields">
                    <template v-slot:cell(upd)="row">{{ df(row.item.upd) }}</template>
                    <template v-slot:cell(in)="row">{{ nf(row.item.in) }}</template>
                    <template v-slot:cell(out)="row">{{ nf(row.item.out) }}</template>
                    <template v-slot:cell(bln)="row">{{ nf(row.item.bln) }}</template>
                    <template v-slot:cell(payments)="row">{{ nf(row.item.payments) }}</template>
                    <template v-slot:cell(invoices)="row">{{ nf(row.item.invoices) }}</template>
                </b-table>
                <p v-else>
                    <br/><br/><br/>Нет элементов для отображения.<br/><br/><br/>
                </p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios'
import params from '../utils/params.js'
import url from '../utils/url.js'
import response from '../utils/response.js'
import { saveAs } from 'file-saver'
import log from '../utils/log.js'
import dateformat from 'dateformat'
import moment from 'moment'
import ns from 'number-string'

export default {
    data () {
        return {
            reptype: 'payments',
            error: null,
            spinner: false,
            start: new Date(moment().add(-3, 'months')),
            end: new Date(moment()),
            noEmpty: true,
            reportList: [],
            reportFields: [
                { key: 'acc', label: 'Л/С', sortable: true} , 
                { key: 'in', label: 'Вх.балнс', sortable: true},
                { key: 'payments', label: 'Оплаты', sortable: true} , 
                { key: 'invoices', label: 'Начисления', sortable: true},
                { key: 'out', label: 'Исх.баланс', sortable: true},
                { key: 'bln', label: 'Тек.баланс', sortable: true},
                { key: 'upd', label: 'Обновлено', sortable: true},
            ],
            reportPdf: null
        }
    },
    computed: {
        preparedList() {
            return this.noEmpty === true ? this.reportList.filter(el => el.payments != 0) : this.reportList
        }
    },
    methods: {
        reportTypeChange (v) {
            if (v === 'noinvoices') this.noEmpty = false
            else this.noEmpty = true
        },
        nf: v =>  ns.toClean(v, {
            decimalMark: ".",
            thousandSeperator: " ",
            maxPrecision: 2,
            minPrecision: 2,
        }),
        df: v => v ? dateformat(v, 'yyyy.mm.dd') : '-', 
        createReq (format) { 
            return params({
                start: this.start,
                end: this.end,
                format: format,
                type: this.reptype
            })
        },

        loadList() {
            this.reportList = []
            this.spinner = true
            this.error = null
            axios.get(url('/rosdolg'), this.createReq('json'))
            .then(resp => {
                const res = response(resp)
                if(res && res.list && Array.isArray(res.list)) {
                    if( res.list.length === 0) this.error = 'По данному запросу ничего не найдено'
                    else this.reportList = res.list
                } else this.error = 'unknown response'
            })
            .catch(err => this.error = err.message)
            .finally(() => this.spinner = false)
        },
        loadReport(format) {
            this.spinner = true
            this.error = null
            const ur = url(`/rosdolg?key=${window.$key}&start=${this.df(this.start)}&end=${this.df(this.end)}&format=${format}&type=${this.reptype}`)
            log(ur)
            fetch(ur, { 
                headers: { Accept: `application/${format}` },
                responseType: 'arraybuffer' })
            .then(resp => {
                if (resp.ok) resp.blob().then(bl => saveAs(bl,`rep-rosdolg-${this.df(this.start)}-${this.df(this.end)}.${format}`))
                else this.error = 'Network error'
                this.spinner = false
            }).catch(err => {
                this.error = err.message
                this.spinner = false
            })
        }
    }
}
</script>