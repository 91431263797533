<template>
<div>
    <b-row>
        <b-col cols=3>
            <b-overlay :show="spinner">
                <b-card>
                    <template v-if="items.length === 0 || file === null">
                        <b-form-file v-model="file" @input="readXlsx" :state="Boolean(file)" placeholder="" browse-text="Выбрать"/>
                        <hr/>
                        <p>Выберите актуальный Excel файл с реестром клиентов, кому выставлены претензии.</p>
                    </template>
                    <template v-else>
                        <p>Загружено элементов: {{ items.length }}</p>
                        <b-button @click="resetXlsx" block variant="warning">Сбросить список</b-button>
                        <hr/>
                        <b-form-datepicker v-model="start" locale="ru-RU" placeholder="Начальная дата" 
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"/>
                        <br />
                        <b-form-datepicker v-model="end" locale="ru-RU" placeholder="Конечная дата" 
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"/>
                        <br />
                        <b-button @click="loadPayments" block variant="success">Загрузить данные по оплатам</b-button>
                        <template v-if="tableUnfound.length > 0">
                            <hr/>
                            <p>Не найдено данных для {{tableUnfound.length}} элементов из Excel файла:</p>
                            <p>
                                <span v-for="(item, index) of tableUnfound" :key="index"><hr/>{{ item.contract }},<br/>{{ item.name }}</span>
                            </p>
                        </template>
                    </template>
                </b-card>
            </b-overlay>
            <p v-if="error != null" style="color:red;">{{ error }}</p>
        </b-col>
        <b-col cols=9>
            <b-overlay :show="spinner">
                <b-table v-if="table.length > 0" :items="table" :fields="tfields" small borderless responsive="sm">
                    <template v-slot:cell(invoices)="row">
                        <b-link @click="loadInfo(row.item.mid)">{{ itemText(row.item.invoices) }}</b-link>
                    </template>
                    <template v-slot:cell(payments)="row">
                        <b-link @click="loadInfo(row.item.mid)">{{ itemText(row.item.payments) }}</b-link>
                    </template>
                    <template v-slot:cell(src_num)="row">
                        <div style="width:20pt">{{ row.item.src_num }}</div>
                    </template>
                    <template v-slot:cell(src_contract)="row">
                        <div style="width:100pt">{{ row.item.src_contract }}</div>
                    </template>
                    <template v-slot:cell(src_name)="row">
                        <div style="width:150pt">{{ row.item.src_name }}</div>
                    </template>
                </b-table>
                <p v-else style="padding-top: 60pt;padding-bottom: 60pt;"><center>Данные для отображения отсутствуют...</center></p>
            </b-overlay>
        </b-col>
    </b-row>
    <!-- info modal >> exact to component -->
    <b-modal id="bv-modal-info" hide-footer scrollable size="xl">
        <template v-if="modal_error">{{ modal_object }}</template>
        <template v-if="!modal_error">
            <eco-client-view-compact :client="modal_object" />
        </template>
    </b-modal>
</div>
</template>

<script>
import readXlsxFile from 'read-excel-file'
import axios from 'axios'
import params from '../utils/params.js'
import qbody from '../utils/qbody.js'
import url from '../utils/url.js'
import response from '../utils/response.js'
import moment from 'moment'
import clientViewCompact from './clientview.compact.vue' 

export default {
    components: {
        'eco-client-view-compact': clientViewCompact
    },
    data() {
        return {
            file: null,
            msg: '',
            items: [],
            remitems: [],
            start: new Date(moment().add(-3, 'months')),
            end: new Date(moment()),
            spinner: false,
            error: '',
            modal_mid: null,
            modal_object: null,
            modal_error: null,
        }
    },
    methods: {
        itemText (array) {
            if (array && Array.isArray(array)) {
                const count = array.length
                const summary = Math.round(array.reduce((acc, cur) => acc += cur.summary, 0) * 100)/100
                return count === 0 ? 'Отсутвуют' : `${count} шт. на ${summary} руб.`
            } else return 'Не загружено'
        },
        resetXlsx () {
            this.file = null
            this.items = []
            this.remitems = []
        },
        readXlsx () {
            this.spinner = true
            this.error = ''
            if (this.file) readXlsxFile(this.file).then( rows => {
                this.remitems = []
                this.items = rows.filter(row => row[2]!= null && row[2] !== '')
            }).catch(() => {
                this.error = 'При загрузки файла произошла ошибка. Проверьте, что файл корректный или обратитесь за помощью к техническим специалистам.'
            }).finally(() => this.spinner = false)
        },
        loadPayments () {
            this.remitems = []
            this.spinner = true
            axios.post(url('/company/claim/payments'), qbody({ 
                items: this.itemsForRequest, 
                start: this.start, 
                end: this.end,
            }))
            .then(resp => {
                let res = response(resp)
                res ? this.remitems = res : this.error = 'unknown response'
            })
            .catch(err => this.error = err.message)
            .finally(() => this.spinner = false)
        },
        loadInfo (mid) {
            if (mid) {
                this.modal_mid = mid
                this.modal_object = null
            
                axios.get(
                    url('/company/byid'), 
                    params({ mid: this.modal_mid })
                ).then(resp => {
                    this.modal_object = response(resp)
                    console.log(this.modal_object)
                    this.modal_error = false
                    this.showModal()
                }).catch(err => {
                    this.modal_object = err
                    this.modal_error = true
                    this.showModal()
                })
            } else alert('Для просмотра информации  необходимо загрузить данные.')
        },
        showModal() {
            this.$bvModal.show('bv-modal-info')
        },
    },
    computed: {
        itemsForRequest () {
            return this.table.map(el => el.src_contract)
        },
        table () {
            return this.items.map(item => {
                const sel = this.remitems.filter(el => el.contract === item[2])
                const remitem = sel.length === 1 ? sel[0] : {}
                return {
                    src_num: item[0],
                    src_name: item[1],
                    src_contract: item[2],
                    payments: remitem.payments,
                    invoices: remitem.invoices,
                    mid: remitem.mid,
                }
            })
        },
        tableUnfound () {
            return this.remitems.length > 0 ? 
                this.items
                    .map(item => this.remitems.filter(el => el.contract === item[2]).length === 0 ? { contract: item[2], name: item[1] } : null)
                    .filter(el => el !== null) : []
        },
        tfields() { 
            return [
                { key: 'src_name', label: 'Наименование', sortable: true},
                { key: 'src_contract', label: 'Договор', sortable: true},
                this.remitems.length > 0 ? { key: 'payments', label: 'Оплаты за период', sortable: false} : null,
                this.remitems.length > 0 ? { key: 'invoices', label: 'Начисления за период', sortable: false} : null,
            ]
        }
    }
}
</script>