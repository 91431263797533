<template>
    <div>
        <template v-if="!show">Ошибка данных :(</template>
        <template v-if="show">
            <b-row>
                <b-col>
                    <div v-for="(item, index) of dataList" :key="index">
                        <span class="text-muted">{{item[0]}} : </span><span class="text-dark">{{item[1]}}</span>
                    </div>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="6">
                    <strong>Начисления</strong>
                    <div v-for="(item, index) of dataListInvoices" :key="index"><span class="text-dark">{{item.title}} : {{item.summary}} RUB</span><br /></div>
                </b-col>
                <b-col cols="6">
                    <strong>Оплаты</strong>
                    <div v-for="(item, index) of dataListPayments" :key="index"><span class="text-dark">{{item.title}} : {{item.summary}} RUB</span><br /></div>
                </b-col>
            </b-row>
        </template>
        
    </div>
</template>

<script>
import dateformat from 'dateformat'
export default {
    name: 'eco-client-view-compact',
    props: ['client'],
    data() {
        return {
            empty: true
        }
    },
    computed: {
        show() {
            return this.client ? true : false
        },
        dataList() {
            return [
                ['Наименование', this.client.name.default],
                ['Договор', this.client.contract.title],
                ['Лицевой счет / код', this.client.contract.account + ' / ' + this.client.code],
                ['ИНН / КПП', this.client.identifiers.inn + ' / ' + this.client.identifiers.kpp],
                ['Адрес', this.client.contacts.addresses.legal.raw.string],
                ['Баланс', this.client.flags.balance*-1 + ' руб. на ' + dateformat(new Date(this.client.flags.balancedate), 'dd.mm.yyyy')],
            ]
        },
        dataListInvoices() {
            let res = []
            if(this.client && this.client.contract && this.client.contract.invoices && Array.isArray(this.client.contract.invoices)) {
                for(let item of this.client.contract.invoices) {
                    if(item['#document_type'] === 12 || item['#document_type'] === 17) {
                        let resItem = {
                            title: item.title,
                            summary: item.total.summary
                        }
                        res.push(resItem)
                    }
                }
            }
            return res
        },
        dataListPayments() {
            let res = []
            if(this.client && this.client.contract && this.client.contract.payments && Array.isArray(this.client.contract.payments)) {
                for(let item of this.client.contract.payments) {
                    let resItem = {
                        title: item.title,
                        summary: item.total.summary
                    }
                    res.push(resItem)
                }
            }
            return res
        }
    },
    methods: {
        
    },
    mounted() {

    },
}
</script>

<style scoped>
    .text-muted { font-family: 'Ubuntu mono', monospace}
    .text-dark { font-family: 'Ubuntu mono', monospace}
</style>