<template>
    <div>
        <b-row>
            <b-col cols="12" md="6">
                <b-input v-model="filter.balance" placeholder="Баланс больше чем (например: 1000)"></b-input>
                <b-input v-model="filter.address" placeholder="Любой элемент адреса (например: Ишимбай Ленина 12)"></b-input>
                <b-form-select v-model="filter.region" placeholder="Территориальная принадлежность"/>
            </b-col>
            <b-col cols="12" md="6">
                <b-input v-model="filter.account" placeholder="Лицевой счет (например: 020100092770)"></b-input>
                <b-input v-model="filter.contract" placeholder="Номер договора (например: ТКО/Ф050118414)"></b-input>
                <b-input v-model="filter.code" placeholder="Шестисимвольный код (например: 000005)"></b-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="12">
                <b-button @click="getList" variant="success" block>
                    <b-spinner v-if="spinner" small type="grow"/>&nbsp; Найти
                </b-button>
                <b-button @click="resetFilter" variant="outline-warning" block>Сбросить фильтр</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <hr/>
                <p><b>Всего найдено: {{list.length}}</b> 
                    <span v-if="list.length > 999" class="text-danger"><br/>Количество найденных элементов слишком велико. 
                        Максимально отображаемое число элементов 1000. Ужесточите параметры фильтрации.</span>
                </p>
                <b-table small fixed
                    :items="list" 
                    :fields="fields">
                    <template v-slot:cell(balance)="row">
                        <span :class="row.item.balance > 0 ? 'text-danger' : 'text-primary'">{{row.item.balance}}</span>
                    </template>
                    <template v-slot:cell(action)="row">
                        <b-button  small variant="outline-primary" block
                            v-if="row.item.balance > 0"
                            @click="claimCreate(row.item.mid)">В суд!</b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios'
import params from '../utils/params.js'
import url from '../utils/url.js'
import response from '../utils/response.js'

export default {
    props: {
        createhandler: Function
    },
    data() {
        return {
            filter : {
                balance: '',
                address: '',
                region: 0,
                account: '',
                contract: '',
                code: '',
            },
            list: [],
            sort: 'account',
            fields: [
                { key: 'account', label: 'Лицевой счет'},
                { key: 'name', label: 'ФИО'},
                { key: 'address', label: 'Адрес'},
                { key: 'balance', label: 'Баланс'},
                { key: 'action', label: ''}
            ],
            error: null,
            spinner: false
        }
    },
    methods: {
        claimCreate(mid) {
            if(this.createhandler) { 
                this.createhandler(mid)
            } else {
                this.error = 'Обработчик не определен'
            } 
        },
        getList() {
            this.spinner = true
            axios.get(
                url('/customer/list/claimers'), 
                params({
                    empty: 'empty',
                    balance: this.filter.balance,
                    address: this.filter.address,
                    region: this.filter.region,
                    account: this.filter.account,
                    contract: this.filter.contract,
                    code: this.filter.code
                })
            )
            .then(resp => {
                this.spinner = false
                let res = response(resp)
                if(res && res.count) {
                    this.list = res.list
                } else this.error = 'unknown response'
            })
            .catch(err => {
                this.spinner = false
                this.error =  err.message
            })
        },
        resetFilter() {
            this.filter = {
                balance: '',
                address: '',
                region: 0,
                account: '',
                contract: '',
                code: ''
            }
        }
    }
}
</script>

<style scoped>
    input {
        margin-bottom: 10px;
    }
</style>
