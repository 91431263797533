<template>
    <b-modal id="claim-create-modal" title="Новая претензия" hide-footer size="xl">
        <b-spinner v-if="spinner" small type="grow"/>
        <b v-if="error" class="text-danger">{{error}}</b>
        <b-tabs content-class="mt-3" v-if="customer && customer.mid">
            <b-tab title="Новая претензия" active>
                <b-row >
                    <b-col>
                        <span>ФИО: <b>{{ vget('customer.name.full') }}</b></span><br/>
                        <span>Договор: <b>{{ vget('customer.contract.number') }}</b></span><br/>
                        <span>Код: <b>{{ vget('customer.code') }}</b></span><br/>
                        <span>Лицевой счет: <b>{{ vget('customer.contract.account') }}</b></span><br/>
                        <span>Адрес: <b>{{ vget('customer.contacts.addresses.legal.raw.string') }}</b></span><br/>
                        <span>Баланс: <b>{{ vget('customer.flags.balance') }}</b></span><br/>
                        <span>Укажаите количество собственников (по умочанию 1): {{owners}} 
                            <b-button small :disabled="owners > 4" variant="outline-success" @click="owners++">+</b-button>&nbsp;
                            <b-button small :disabled="owners <= 1" variant="outline-primary" @click="owners--">-</b-button>
                            <b-input v-for="(k,index) in owners" :key="index + 10000" 
                                style="margin-top:10px;" :disabled="k===1"
                                v-model="ownerslist[k]" :placeholder="'ФИО собственника #' + k"/>
                        </span>
                        <hr/>
                        <b-form-select v-model="worker" :options="workersListForSelected"/>
                        <div v-if="selectedWorker">
                            <br/>
                            <span>ФИО: <b>{{ vget('selectedWorker.name') }}</b></span><br/>
                            <span>Полномочия: <b>{{ vget('selectedWorker.perm') }}</b></span><br/>
                            <span>Тел: <b>{{ vget('selectedWorker.tel') }}</b></span><br/>
                        </div>
                       
                        <hr/>
                        <div v-if="judge.id > 0">
                            <b>Выбраннный судебный участок:</b><br/>
                            <span>Идентификатор участка: {{ judge.id }}</span><br/>
                            <span>Наименование: <b>{{ judge.title }}</b></span><br/>
                            <span>Судья: <b>{{ judge.judge }}</b></span><br/>
                            <span>Электронный адрес: {{ judge.email }}</span><br/>
                            <span>Телефон: {{ judge.phone }}</span><br/>
                            <span>Сайт: <a :href="judge.link" target="blank">{{ judge.link }}</a></span><br/>
                            <span>Адрес: {{ judge.address }}</span><br/>
                            <hr/>
                        </div>
                        <b-button  v-if="judge.id > 0 && worker > 0 && !link" block variant="success" @click="createClaimDocument">Сформировать претензию</b-button>
                        <b-button :href="claimLinkDownload" v-if="link" block variant="primary" target="blank">Скачать</b-button>
                        <strong v-if="showjudgefinder"><hr/>О   пределить подсудность:</strong>
                        <judge ref="judgeListLoader" :query.sync="getJudgesQuery" :selected.sync="judge" :close="hideJudgeFinder"
                            v-if="showjudgefinder"/> 
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Детали по текуцщим начислениям">
                <b-row>
                    <b-col>
                        <b>Текущий баланс: {{ invoicesTotalSummary - paymentsTotalSummary}}</b>
                        <hr/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <strong>Начисления:</strong>
                        <div v-if="customer && customer.contract && customer.contract.invoices">
                            <span v-for="(item, index) of customer.contract.invoices" :key='index'>
                                {{index + 1}}. {{item.title}} : <b>{{item.total.summary}} руб.</b><br/>
                            </span>
                            <b>Всего начислено: {{ invoicesTotalSummary }}</b>
                        </div>
                        <b v-else>Начисления отсутсвуют</b>
                    </b-col>
                    <b-col>
                        <strong>Оплаты:</strong>
                        <div v-if="customer && customer.contract && customer.contract.payments">
                            <span v-for="(item, index) of customer.contract.payments" :key='index + 1000'>
                                {{index + 1}}. {{item.title}} : <b>{{item.total.summary}} руб.</b><br/>
                            </span>
                            <b>Всего оплачено: {{ paymentsTotalSummary }}</b>
                        </div>
                        <b v-else>Оплаты отсутсвуют</b>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab  :title="customer && customer.claims ? `Все претензии [${customer.claims.length}]` : 'Все претензии [0]'">
                <b-row >
                    <b-col>
                        <div v-if="customer && customer.claims">
                            <div v-for="(item, index) of customer.claims" :key="index">
                                <b-button :href="claimLinkDownloadForList(item.file)" block variant="link" target="blank">Дата:{{ item.date }}, долг:{{ item.balance }}, файл:{{ item.file }}</b-button>
                            </div>                   
                        </div>
                        <div v-else>
                            <p>Ранее сформированные документы отсутствуют</p>
                        </div>
                    </b-col>
                </b-row>
            </b-tab>
        </b-tabs>
    </b-modal>
</template>
<script>
import axios from 'axios'
import params from '../utils/params.js'
import url from '../utils/url.js'
import response from '../utils/response.js'
import judge from './judge.vue'
import lodash from 'lodash'
import helpers from '../model/helpers.js'

export default {
    components:{
        judge
    },
    data() {
        return {
            mid: null,
            customer: {
                mid: null
            },
            error: null,
            spinner: false,
            judge: {
                id: null
            },
            worker: null,
            showjudgefinder: true,
            link: null,
            owners: 1,
            ownerslist: ['','','','']
        }
    },
    methods:{
        createClaimDocument() {
            this.link = null
            axios.get(
                url('/customer/claim/create'), 
                params({ 
                    customermid: this.customer.mid, 
                    workermid: this.worker, 
                    judgeid: this.judge.id,
                    owners: this.owners,
                    ownerslist: this.ownerslist
                }),
                { responseType: 'blob' }
            ).then(resp => {
                let res = response(resp)
                if(res && res.link) {
                    this.link = res.link
                } else this.error = 'Некорректный ответ от сервера'
            }).catch(err => this.error = err.message)
        },
        hideJudgeFinder() {
            this.showjudgefinder = false
        },
        vget(itemDomainName) {
            return lodash.get(this, itemDomainName, 'н/д')
        },
        createNew(mid) {
            this.owners = 1
            this.customer = null
            this.error = null
            this.spinner = true
            this.mid = mid
            this.link = null
            this.judge = { id: null }
            this.$bvModal.show('claim-create-modal')
            if(this.mid > 0) {
                this.loadCustomerInfo()
            } else this.error = `Некорректный параметр : ${this.mid}`
        },
        loadCustomerInfo() {
            axios.get(
                url('/customer/byid/actual'), 
                params({ mid: this.mid })
            ).then(resp => {
                let res = response(resp)
                if(res && res.mid) {
                    this.customer = res
                    this.ownerslist[1] = this.customer.name.full
                } else  this.error = 'Некорректный ответ от сервера'
                this.spinner = false
            }).catch(err => {
                this.error = err
                this.spinner = false
            })
        },
        claimLinkDownloadForList(customLink) {
            return url('/download' + str(params({
                link: customLink,
                mid: this.customer.mid,
                type: 'claims'
            })))
        }
    },
    computed: {
        selectedWorker() {
            let workers = helpers.getHelper('workers')
            if(workers) {
                let res = workers.filter(item => item.mid === this.worker)
                if(res.length===1) {
                    return res[0]
                }
            } 
            return null
        },
        workersListForSelected() {
            let res =  helpers.getHelper('workers').map(item => {
                return {
                    value: item.mid,
                    text: item.name
                }
            })
            return [ {
                    value: null,
                    text: '-- Необходимо выбрать сотрудника --',
                    disabled: true
                },
                ...res]
        },
        getJudgesQuery() {
            if(this.customer && lodash.get(this.customer, 'contacts.addresses.legal')) {
                return this.customer.contacts.addresses.legal.query
            }
            else return null
        },
        invoicesTotalSummary() {
            if(this.customer && this.customer.contract && this.customer.contract.invoices) {
                return this.customer.contract.invoices.reduce((acc, item) => acc += item.total.summary, 0)
            } else return 0
        },
        paymentsTotalSummary() {
            if(this.customer && this.customer.contract && this.customer.contract.payments) {
                return this.customer.contract.payments.reduce((acc, item) => acc += item.total.summary, 0)
            } else return 0
        },
        claimLinkDownload() {
            return url('/download' + str(params({
                link: this.link,
                mid: this.customer.mid,
                type: 'claims'
            })))
        }
    },
    created() {

    }
}

function str(params) {
    let s = '?'
    for(let key of Object.keys(params.params)) {
        s += `${key}=${params.params[key]}&`
    }
    return s
}
</script>

<style scoped>
    span, b, strong, p, div {
        font-family: 'Ubuntu mono', monospace;
    }
</style>