import login from './components/login.vue'
import main from './components/main.vue'
import workspace from './components/workspace.vue'
import helpers from './components/helpers.vue'
import sbis from './components/sbis.vue'
import claim from './components/claim.vue'
import sync from './components/sync.vue'
import debt from './components/debt.vue'
import rosdolg from './components/rosdolg.vue'
import claimPayments from './components/claim-payments.vue'

export default [
    { path: '/', component: main },
    { path: '/workspace', component: workspace, children: [
        { path: 'helpers', name: 'helpers', component: helpers },
        { path: 'sbis', name: 'sbis', component: sbis },
        { path: 'claim', name: 'claim', component: claim },
        { path: 'claimpayments', name: 'claim-payments', component: claimPayments },
        { path: 'sync', name: 'sync', component: sync },
        { path: 'debt', name: 'debt', component: debt },
        { path: 'rosdolg', name: 'rosdolg', component: rosdolg },
    ]},
    { path: '/login', name:'login', component: login, },
]