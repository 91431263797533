import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueCookie from 'vue-cookie'
import VueMeta from 'vue-meta'
import routes from './routes'
import subNavRow from './components/sub-nav-row.vue'
import helpers from './model/helpers.js'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueCookie)
Vue.use(VueMeta)
Vue.use(BootstrapVue)
Vue.component('sub-nav-row', subNavRow)

Vue.prototype.$key = ''
helpers.load()

new Vue({
  router: new VueRouter({routes: routes}),
  render: h => h(App),
}).$mount('#app')