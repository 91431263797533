import axios from 'axios'
import params from '../utils/params.js'
import url from '../utils/url.js'
import response from '../utils/response.js'

export default function(mid, _this) {
    _this.modal.mid = mid
    _this.modal.object = null
    
    axios.get(
        url('/company/byid'), 
        params({mid: _this.modal.mid}))
    .then(resp => {
        _this.modal.object = response(resp)
        _this.modal.error = false
        _this.showModal()
    })
    .catch(err => {
        _this.modal.object = err
        _this.modal.error = true
        _this.showModal()
    })
}