<template>
    <div>
        <eco-navigation />
        <router-view />
        <center v-if="showHello" style="margin-top:70pt;margin-bottom:70pt;">Сервисное приложение | ООО РО "Эко-Сити"</center>
        <eco-footer />
    </div>
</template>

<script>
import econavi from './navigation.vue'
import ecofooter from './footer.vue'

export default {
    name: 'eco-workspace',
    components: {
        'eco-navigation': econavi,
        'eco-footer': ecofooter,
    },
    methods: {
    },
    computed: {
        showHello () {
            return this.$route.path === '/workspace'
        }
    },
    beforeCreate() {
        if(checkKey() === false) {
            this.$router.push('/')
        }
    },
}

function checkKey() {
    let res = true 
    if(!window.$key) res = false
    return res
}
</script>