import axios from 'axios'
import qbody from '../utils/qbody.js'
import url from '../utils/url.js'
import response from '../utils/response.js'

export default function (_this) {
    _this.error.text = ''
    _this.error.show = false
    _this.list.items = []
    _this.clearSelection()
    let allowRequest = false
    allowRequest = _this.invoicetype && _this.period ? true : allowRequest
    allowRequest = _this.invoicetype && _this.verify.start && _this.verify.end && (_this.verify.start > _this.verify.end) ? true : allowRequest
    if(allowRequest) {
        _this.spinerAction()
        axios.post(
            url('/company/list/short/sbis'), 
            qbody({ 
                period: _this.period,
                group: _this.group,
                invoicetype: _this.invoicetype, 
                start: _this.verify.start,
                end: _this.verify.end,
                inntext: _this.inntext
            }))
        .then(resp => {
            resp = response(resp)
            if(resp) {
                _this.list.items = _this.extendList(resp.list)
            } else {
                _this.error.text = 'Ошибка получения данных :('
                _this.error.show = true
            }
            _this.spinerAction(0)
        })
        .catch(err => {
            _this.error.text = err.message
            _this.error.show = true
            _this.spinerAction(0)
        })
    } 
}