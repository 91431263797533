<template>
    <div>
        <sub-nav-row title="Экспорт в СБИС" />
        <b-row>
            <!-- left side -->
            <b-col cols="3">
                <b-form-select @change="loadList" 
                    value-field="mid" text-field="name"
                    v-model="invoicetype" :options="invoicetypes"/>
                <br /><br />
                <template v-if="invoicetype != '34'">
                    <b-form-select @change="loadList"
                        value-field="mid" text-field="name"
                        v-model="period" :options="periods"/>
                <br /><br />
                </template>
                <b-form-select @change="loadList" 
                    value-field="mid" text-field="name"
                    v-model="group" :options="groups"/>
                <br />
                <template v-if="invoicetype == '34'">
                    <br />
                    <b-form-datepicker v-model="verify.start" locale="ru-RU" placeholder="Начальная дата" 
                        @context="loadList" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"/>
                    <br />
                    <b-form-datepicker v-model="verify.end" locale="ru-RU" placeholder="Конечная дата" 
                        @context="loadList" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"/>
                    <br />
                    <b-button @click="setVerifyInterval('Q1')" variant="link">Q1</b-button>
                    <b-button @click="setVerifyInterval('Q2')" variant="link">Q2</b-button>
                    <b-button @click="setVerifyInterval('Q3')" variant="link">Q3</b-button>
                    <b-button @click="setVerifyInterval('Q4')" variant="link">Q4</b-button>
                    <br />
                    <b-button @click="setVerifyInterval('H1')" variant="link">H1</b-button>
                    <b-button @click="setVerifyInterval('H2')" variant="link">H2</b-button>
                    <b-button @click="setVerifyInterval('FY')" variant="link">FY</b-button>
                    <hr/>
                </template>
                <div v-if="group === 0" >
                    <br /><b-form-textarea rows="9" 
                        placeholder="Скопируйте и вставьте текст содержащий один или несколько ИНН"
                        v-model="inntext"/>
                    <b-button @click="inntext=''" v-if="inntext && inntext.length > 0" block variant="link">Очистить текст</b-button>
                    <br/><b-button @click="loadList" block variant="outline-success" v-if="period > 0  && invoicetype > 0 && inntext && inntext.length > 0">Загрузить</b-button>
                </div>

                <div class="text-center" v-if="spinner">
                    <br /><b-spinner variant="primary" />
                </div>
                <b-alert v-model="error.show" variant="danger" dismissible>{{error.text}}</b-alert>
                <br />
                <template v-if="list.items.length > 0">
                    <p>Загружено: [ {{list.items.length}} ]</p>
                    <p>Страниц: [ {{ Math.round(list.items.length / list.pagesize) }} ]</p>
                    <b-button block @click="selectAll" variant="outline-primary">Выделить все</b-button><br />
                </template>
                <template v-if="list.selected.count > 0">
                    <b-button block @click="clearSelection" variant="outline-warning">Отменить все</b-button><br />
                    <b-button block @click="loadPack" variant="outline-danger">Сформировать пакет</b-button><br />
                    <p>Выбрано</p>
                    <p>Контрагентов: [ {{list.selected.count}} ]</p>
                    <p>Документов: [ {{list.selected.docscount}} ]</p>
                    <p>Коды: {{list.selected.codes.length > 10 ? ' их очень много :) ' : list.selected.codes}}</p>
                    <p>Кроме документов: {{list.selected.excludes.length > 10 ? ' их очень много :) ' : list.selected.excludes.map(x => x.doc)}}</p>
                </template>
            </b-col>
            <!-- right side -->
            <b-col cols="9" v-if="list.items.length === 0">
                <p class="text-center"><span class="text-muted">
                    <br />Список пуст. 
                    <br />Либо параметры дают нулевой результат, либо вы еще не загрузили список. 
                    <br />Загрузите список.
                </span></p>
            </b-col>
            <b-col cols="9" v-if="list.items.length > 0">
                <b-row>
                    <b-col><b-form-input v-model="search" type="search" placeholder="Поиск" /></b-col>
                    <b-col>
                        <b-pagination align="fill" class="my-0"
                            v-model="list.page"
                            :total-rows="list.items.length"
                            :per-page="list.pagesize" />
                    </b-col>
                </b-row>
                <br />
                <b-table small fixed borderless responsive="sm"
                    :filter-function="filterfunction"
                    :per-page="list.pagesize"
                    :current-page="list.page"
                    :items="list.items"
                    :fields="list.fields"
                    :filter="search">
                    <template v-slot:cell(checked)="row">
                        <b-button size="sm" block variant="light" @click="selectItem(row.item.mid)">
                            <span :class="rowClass(row)">{{ btnTitle(row) }}</span>
                        </b-button>
                    </template>
                    <template v-slot:cell(contract)="row">
                        <b-button size="sm" block variant="light" @click="loadInfo(row.item.mid)">
                            <span :class="rowClass(row)">{{row.item.contract}} / {{row.item.mid}}</span>
                        </b-button>
                    </template>
                    <template v-slot:cell(name)="row">
                        <b-button size="sm" block variant="light" @click="loadInfo(row.item.mid)">
                            <span :class="rowClass(row)">{{row.item.name}}</span>
                        </b-button>
                    </template>
                    <template v-slot:cell(invoices)="row">
                        <b-button v-for="(invoice, index) of row.item.invoices" :key="`${row.item.mid}-${index}`" size="sm" block variant="light" 
                            @click="selectItem(row.item.mid, invoice.mid)">
                            <span :class="docClass(row, invoice.mid)">{{invoice.summary}}р<br/>{{dateformat(invoice.date, 'dd.mm.yyyy')}}<br/>#{{invoice.number}}</span>
                        </b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <!-- info modal >> exact to component -->
        <b-modal id="bv-modal-info" hide-footer scrollable size="xl">
            <template v-if="modal.error">{{ modal.object }}</template>
            <template v-if="!modal.error">
                <eco-client-view-compact :client="modal.object" />
            </template>
        </b-modal>
    </div>
</template>

<script>
//TODO sync & status sync
//TODO helpers
import clientViewCompact from './clientview.compact.vue' 
import helpers from '../model/helpers.js'
import _loadList from './sbis.vue.loadlist.js'
import _loadInfo from './sbis.vue.loadinfo.js'
import _loadPack from './sbis.vue.loadpack.js'
import dateformat from 'dateformat'

const invoiceTypes = [
    { mid: null, name: 'Тип документа' },
    { mid: '12', name: 'УПД' },
    { mid: '17', name: 'УКД' },
    { mid: '34', name: 'Акт сверки' }
]

const tableFields = [
    { key: 'checked',   label: 'В СБИС',         sortable: true }, 
    { key: 'contract',  label: 'Договор / Код',  sortable: true },
    { key: 'name',      label: 'Наименование',   sortable: true }, 
    { key: 'invoices',  label: 'Начисления',     sortable: true }
]

export default {
    name:'eco-sbis',
    components: {
        'eco-client-view-compact': clientViewCompact
    },
    computed: {

    },
    data() {
        return {
            period: null,
            periods: [{mid:null, name:'Выбрать период'}, ...helpers.getHelper('period')],
            groups: [
                { mid:null, name:'Выбрать категорию или все' }, 
                { mid:0, name:'Из списка ИНН *********' }, 
                ...helpers.getHelper('customer_group').filter(i => ![2,9,11].includes(i.mid))],
            group: null,
            invoicetypes: invoiceTypes,
            verify: {
                start: null,
                end: null
            },
            invoicetype: null,
            spinner: false,
            search: null,
            inntext: null,
            modal: {
                mid: null,
                object: null,
                error: false
            },
            list: {
                pagesize: 10,
                page: 1,
                items: [],
                fields: tableFields,
                selected:{
                    count: 0,
                    docscount: 0,
                    codes: [],
                    excludes: []
                }
            },
            error:{
                show: false,
                text: null
            }
        }
    },
    methods: {
        dateformat(v,f) { return dateformat(v,f)},
        prepareSelected() {
            this.list.selected.docscount = 0
            let selectedItems = this.list.items.filter(i => i.checked === true)
            this.list.selected.codes = [] 
            this.list.selected.excludes = [] 
            for(let selectedItem of selectedItems) {
                this.list.selected.codes.push(selectedItem.mid)
                for(let invoice of selectedItem.invoices) {
                    if(invoice.checked === true) {
                        this.list.selected.docscount++
                    } else {
                        this.list.selected.excludes.push({ 
                            item: selectedItem.mid, 
                            doc: invoice.mid
                        })
                    } 
                }
            }
            this.list.selected.count = this.list.selected.codes.length
        },
        clearSelection() {
            if(this.list.selected.count > 0){
                this.list.items = this.list.items.map(item => {
                    item.checked = false
                    for(let inv of item.invoices) inv.checked = false  
                    return item
                })
                this.prepareSelected()
            }
        },
        selectPeriod(mid) {
            this.period = mid
            this.loadList()
        },
        selectItem(mid, docmid) {
            for(let item of this.list.items) {
                if(item.mid === mid ) {
                    if(docmid) {
                        for(let inv of item.invoices) {
                            if(inv.mid === docmid) {
                                inv.checked = inv.checked === true ? false : true
                            }
                        }
                        item.checked = item.invoices.filter(i => i.checked === true).length > 0 ? true : false
                    } else {
                        item.checked = item.checked === true ? false : true
                        for(let inv of item.invoices) inv.checked = item.checked  
                    }
                    this.prepareSelected()
                    return
                }
            }
        },
        selectAll() {
            for(let item of this.list.items) {
                item.checked =  true  
                for(let inv of item.invoices) {
                    inv.checked = true
                }
            }
            this.prepareSelected()
        },
        loadPack() { 
            _loadPack(this) 
        },
        loadList() { 
            _loadList(this) 
        },
        loadInfo(mid) { 
            _loadInfo(mid, this) 
        },
        showModal() {
            this.$bvModal.show('bv-modal-info')
        },
        extendList(items) {
            if(Array.isArray(items)) {
                return items.map(item => {
                    item.checked = false
                    return item
                })
            } else return []
        },
        spinerAction(state) {
            this.spinner = state === 0 ? false : true
        },
        rowClass: (row) => row.item.checked ? 'text-danger' : '',
        docClass: (row, docmid) => row.item.invoices.filter(i => i.mid === docmid && i.checked == true).length === 1 ? 'text-danger' : '',
        btnTitle: (row) => row.item.checked ? '- Отменить' : '+ Добавить', 
        filterfunction(item) {
            if(this.search) {
                if(item.name.toUpperCase().includes(this.search.toUpperCase())) return true
                if(String(item.mid).includes(this.search)) return true
                if(item.contract.includes(this.search)) return true
                return false
            } else return true
        },
        selectAllFound() {

        },
        setVerifyInterval(value) {
            const currentYear = `${new Date().getFullYear()}`
            if (value === 'Q1') {
                this.verify.end = new Date(currentYear + '-01-01')
                this.verify.start = new Date(currentYear + '-03-31')
            }
            if (value === 'Q2') {
                this.verify.end = new Date(currentYear + '-04-01')
                this.verify.start = new Date(currentYear + '-06-30')
            }
            if (value === 'Q3') {
                this.verify.end = new Date(currentYear + '-07-01')
                this.verify.start = new Date(currentYear + '-09-30')
            }
            if (value === 'Q4') {
                this.verify.end = new Date(currentYear + '-10-01')
                this.verify.start = new Date(currentYear + '-12-31')
            }
            if (value === 'H1') {
                this.verify.end = new Date(currentYear + '-01-01')
                this.verify.start = new Date(currentYear + '-06-30')
            }
            if (value === 'H2') {
                this.verify.end = new Date(currentYear + '-07-01')
                this.verify.start = new Date(currentYear + '-12-31')
            }
            if (value === 'FY') {
                this.verify.end = new Date(currentYear + '-01-01')
                this.verify.start = new Date(currentYear + '-12-31')
            }
        }
    },
}
</script>