<template>
    <b-row>
        <b-col>
            <hr />
            Техническая поддержка<br/>
            Телефон: <b-link href="tel:+79656656666">+7 965 665 66 66</b-link><br/>
            Telegram: <b-link href="https://t.me/envoi" target="blank">@envoi</b-link><br/>
            Email: <b-link href="mailto:for@envoi.dev">for@envoi.dev</b-link>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'eco-footer'
}
</script>