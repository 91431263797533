<template>
    <b-row>
        <b-col>
            <h3>{{ title }}</h3>
            <hr />
        </b-col>
    </b-row>
</template>

<script>
export default {
    props:{
        title: String
    },
    name: 'sub-nav-row'
}
</script>