<template>
    <b-row>
        <b-col cols="6">
            <b-button  v-for="(item, index) of list" :key="index" 
                block variant="primary" style="margin-bottom: 20px;" :disabled="!showbutton[item.name]"
                @click="runSync(item.name)">Синхронизировать: {{item.title}}</b-button>
            <hr/>
            <b-button block variant="outline-success" @click="getStatus">Обновить статус</b-button>
            <br/>
            <div v-for="(item, index) of list" :key="index + 100">
                {{item.title}}:
                <span v-if="status[item.name]">
                    {{statusFormat(status[item.name].status)}}, {{dateFormat(status[item.name].updated, 'yyyy.mm.dd HH:MM')}}
                    <i v-if="status[item.name].status === 'inprogress'">
                        <br/>выполнено {{status[item.name].current}} из {{status[item.name].total}}, осталось 
                            {{status[item.name].remaining}} минут
                    </i>
                    <i v-if="status[item.name].status === 'finish'">
                        <br/>выполнено {{status[item.name].current}} из {{status[item.name].total}}
                    </i>
                    <b v-if="status[item.name].errors && status[item.name].errors.length > 0" class='text-danger'>
                        <br/>Количество ошибок {{ status[item.name].errors ? status[item.name].errors.length : 'н/д' }}
                    </b>
                    {{timeCheck(status[item.name])}}
                </span>
                <span v-else>нет данных</span>
                <hr/>
            </div>
        <b class="text-danger">{{error}}</b>
        {{pulse}}
        </b-col>
        <b-col cols="6">
            <b-form-textarea rows="12" 
                placeholder="Скопируйте и вставьте текст содержащий один или несколько ИНН юридических лиц и индивидуальных предпринимателей, нажмите кнопку внизу (она появится если введете хотя-бы один ИНН), а я попробую их синхронизировать."
                v-model="inntext"/>
            <br/>
            <div v-if="inntext.length > 9">
                <b-button block @click="runSyncByInn" variant="primary" :disabled="!showbutton['companybyinn']">Синхронизировать по списку</b-button>
                <b-button block variant="link" @click="inntext=''">Очистить список</b-button>
            </div>
        </b-col>
    </b-row>
</template>

<script>
import axios from 'axios'
import params from '../utils/params.js'
import url from '../utils/url.js'
import response from '../utils/response.js'
import dateFormat from 'dateformat'
import qbody from '../utils/qbody.js'

export default {
    data() {
        return {
            status: {
                helpers: { updated: null },
                points: { updated: null },
                company: { updated: null },
                customer: { updated: null }
            },
            showbutton: {
                helpers: true,
                points: true,
                company: true,
                companybyinn: true,
                customer: true
            },
            error: null,
            inntext: '',
            pulse: '',
        }
    },
    mounted() {
        this.showbutton = {
            helpers: true,
            points: true,
            company: true,
            companybyinn: true,
            customer: true
        }
        this.error = null
        this.getStatus()
        setInterval(this.getStatus, 5000)
        setInterval(this.doPulse, 1000)
    },
    computed: {
        list() {
            let items = [
                ['Справочники', 'helpers'],
                ['Точки поставки', 'points'],
                ['Компании (ЮЛ)', 'company'],
                ['Физические лица (ФЛ)', 'customer'],
            ]
            let res = []
            for(let item of items) {
                res.push({
                    name: item[1],
                    title: item[0]
                })
            }
            return res
        }
    },
    methods: {
        timeCheck(status) {
            if(status && status.updated && status.status === 'inprogress') {
                let timeSpan = new Date() - new Date(status.updated)
                return timeSpan > 100000 ?'[ индикатор исполнения давно не изменялся, возможно процесс сотановлен, повторите повторно ]' : ''
            } return ''
        },
        statusFormat(statString) {
            let res = [
                ['finish', 'завершено'],
                ['inprogress', 'в процессе'],
                ['start', 'начато']
            ].filter(item => item[0] === statString)
            return res.length === 1 ? res[0][1] : statString
        },
        dateFormat: (dateValue, datePattern) => dateFormat(dateValue, datePattern),
        getStatus() {
            axios.get(url('/sync/status'), params({ }))
            .then(resp => {
                let res = response(resp)
                if(res) {
                    this.status.helpers = res.helpers
                    this.status.points = res.points
                    this.status.company = res.company
                    this.status.customer = res.customer
                } else this.error = 'Неизвестный ответ от сервера [статус]'
            }).catch(err => this.error = err)
            this.doPulse()
        },
        runSync(name) {
            this.showbutton[name] = false
            axios.get(url('/sync'), params({
                source: name
            })).then(resp => {
                let res = response(resp)
                if(res) {
                    this.getStatus()
                } else this.error = 'Неизвестный ответ от сервера'
            }).catch(err => this.error = err)            
        },
        runSyncByInn(){
            this.showbutton['companybyinn'] = false
            axios.post(url('/sync/byinnlist'), qbody({
                source: 'company',
                inntext: this.inntext
            })).then(resp => {
                let res = response(resp)
                if(res) {
                    this.getStatus()
                } else this.error = 'Неизвестный ответ от сервера'
            }).catch(err => this.error = err)  
        },
        doPulse() {
            let p = ':'
            if(this.pulse.length < 7) this.pulse += p; else this.pulse = p
        }
    }
}
</script>