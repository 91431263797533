<template>
    <div id="app">
        <b-container>
            <router-view></router-view>
        </b-container>
    </div>
</template>

<script>
import helpers from './model/helpers.js'

export default {
    name: 'app',
    metaInfo:{
        title: 'EcoCity | ServiceApp',
        meta: [{ charset: 'utf-8' }]
    },
    mounted() {
        let keyFromCookie = this.$cookie.get('key')
        if(keyFromCookie && keyFromCookie.length > 10) {
            window.$key = keyFromCookie
            helpers.load()
            this.$router.push('/workspace')
        }
    }
}
</script>

<style>
#app {
  font-family: 'Ubuntu mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
