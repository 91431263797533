<template>
    <b-row>
        <b-col cols="3">
            <b-nav pills align="left">
                <b-nav-item><b class="text-dark">Eco City - Service App</b></b-nav-item>
            </b-nav>
            <hr />
        </b-col>
        <b-col cols="9">
            <b-nav pills align="right">
                <b-nav-item @click="$router.push('/workspace/sbis')" >Экспорт в СБИС</b-nav-item>
                 <b-nav-item-dropdown text="Задолженность">
                    <b-nav-item @click="$router.push('/workspace/claim')">Претензии</b-nav-item>
                    <b-nav-item @click="$router.push('/workspace/claimpayments')">Статус оплат</b-nav-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-nav-item @click="$router.push('/workspace/debt')">Аналитика ДЗ</b-nav-item>
                    <b-nav-item @click="$router.push('/workspace/rosdolg')">Отчеты РосДолг</b-nav-item>
                </b-nav-item-dropdown>
                <b-nav-item @click="$router.push('/workspace/sync')">Синхронизация</b-nav-item>
                <!-- 
                    <b-nav-item @click="$router.push('/workspace/support')" disabled>Обращения</b-nav-item>
                    <b-nav-item @click="$router.push('/workspace/helpers')">Справочники</b-nav-item>
                -->
                <b-nav-item @click="logout" >Выйти</b-nav-item>
            </b-nav>
            <hr />
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'eco-navigation',
    methods: {
        logout() {
            window.$key = null
            this.$cookie.delete('key')
            this.$router.push('/')
        },
        helpers() {
                      
        }
    },
}
</script>