<template>
    <div>
        <h2>Eco City</h2>
        <h2>Service App</h2>
        <br /><br />
        <b-button variant="outline-primary" @click='login'>Авторизуйтесь</b-button>
        <br /><br /><br />
        <a target="_blank" href='https://bmachines.ru'>Binary Machines, LLC</a>
    </div>
</template>

<script>
export default {
    name: 'eco-main',
    methods: {
        login() {
            this.$router.push({
                name: 'login', 
                params: {
                    msg: 'please'
                }
            })
        }
    },
}
</script>