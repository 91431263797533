<template>
    <div>
        <b-row>
            <b-col cols="3">
                <b-overlay :show="spinner" rounded="sm">
                    <strong>Фильтры:</strong>          
                    <b-form-select class="fel" :options="filters.types.options" v-model="filters.types.selected"/> <!-- тип потребителя -->
                    <b-row>
                        <b-col cols="5"><b-input class="fel" v-model="filters.balance1" placeholder="Величина задолженности"/></b-col>
                        <b-col cols="5"><b-input class="fel" v-model="filters.balance2" placeholder="Величина задолженности"/></b-col>
                        <b-col cols="2" class="text-center"><br/><b-link @click="clearBalances">[X]</b-link></b-col>
                    </b-row>
                    
                    <b-form-select class="fel" :options="filters.regions.options" v-model="filters.regions.selected"/> <!-- территория -->
                    <b-form-select class="fel" :options="filters.groups.options" v-model="filters.groups.selected"/> 
                    <!-- <b-form-select class="fel"/>  тариф -->
                    <b-form-select class="fel" :options="filters.debtages.options" v-model="filters.debtages.selected"/> <!-- возраст задолженности -->
                    <b-form-select class="fel" :options="filters.views.options" v-model="filters.views.selected"/>
                    <b-form-select class="fel" :options="filters.groupby.options" v-model="filters.groupby.selected1"/>
                    <b-form-select class="fel" :options="filters.groupby.options" v-model="filters.groupby.selected2" :disabled="!filters.groupby.selected1"/>
                    <b-form-select class="fel" :options="filters.sortby.options" v-model="filters.sortby.selected"/>
                    <b-button block variant="success" @click="getList" class="fel">Показать</b-button>
                    <b-button block variant="outline-success" class="fel">Скопировать ссылку</b-button>
                    <b-button block variant="outline-secondary" class="fel" @click="printReport">Распечатать</b-button>
                </b-overlay>
            </b-col>
            <b-col>
                <div id="reportPrintArea">
                    <debt-tree :items="list" :level="0" :request="request"/>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import deft_filters from './debt.filters.js'
import axios from 'axios'
import params from '../utils/params.js'
import url from '../utils/url.js'
import response from '../utils/response.js'
import debtTreeView from './debt.tree.vue'

export default {
    components: {
        'debt-tree': debtTreeView
    },
    data() {
        return {
            error: null,
            list: [],
            count: 0,
            spinner: false,
            request: {},
            ...deft_filters()
        }
    },
    methods:{
        printReport() {
            let printArea = document.getElementById('reportPrintArea')
            let printWin = window.open()
            printWin.document.write(`
                <style>
                    table { font-family: Arial; font-size:10px; }
                    td { border-bottom:1pt solid #888888; }
                </style>
            `)
            printWin.document.write(printArea.innerHTML)
            printWin.focus()
            printWin.print()
            printWin.close()
        },
        clearBalances() {
            this.filters.balance1 = null
            this.filters.balance2 = null
        },
        getList() {
            this.list = []
            this.request = {
                    type: this.filters.types.selected,
                    balance1: this.filters.balance1,
                    balance2: this.filters.balance2,
                    region: this.filters.regions.selected,
                    group: this.filters.groups.selected,
                    debtage: this.filters.debtages.selected,
                    view: this.filters.views.selected,
                    groupby1: this.filters.groupby.selected1,
                    groupby2: this.filters.groupby.selected2,
                    sortby: this.filters.sortby.selected 
                }
            this.error = null
            this.spinner = true
            axios.get(url('/debt/list'), params(this.request))
            .then(resp => {
                let res = response(resp)
                if(res && res.list) {
                    this.list = res.list
                    this.count = Number(res.count)
                    if(Number(res.count) === 0) {
                        this.error = 'По данному запросу ничего не найдено'
                    }
                } else this.error = 'unknown response'
            })
            .catch(err => { this.error = err.message })
            .finally(() => this.spinner = false)
        }
    }
}
</script>

<style>
 .fel {
        margin-top: 10px;
    }
</style>