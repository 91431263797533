<template>
    <div>
        <b-row>
            <b-col>
                <debtors :createhandler="claimCreate" />
                <createModal ref="createModal" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import debtors from './customers.debtors.vue'
import createModal from './claim-create.modal.vue'

export default {
    props: {

    },
    components: {
        debtors, createModal
    },
    data() {
        return {
        }
    },
    methods: {
        claimCreate(mid) {
            this.$refs.createModal.createNew(mid)
        }
    },
    computed: {

    }
}
</script>

<style scoped>

</style>